import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/ryan.parr/Documents/Apps/sprinklr.design/src/templates/mdx-page.js";
import { Eg } from '../../../components/Docs/Foo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`empowering`}</h3>
    <p>{`Sprinklr is an integrated, intelligent, elite and one of a kind platform that empowers its customers through every step. We intend on reinstilling the same confidence though our voice.`}</p>
    {/*
     Eg.
     Give your agents an omnichannel and real-time view of conversations on social, all at one place, and create personalized user experiences backed by our intelligence.
     View conversations on social and help your agents serve your customers.
     */}
    <Eg pos="Give your agents an omnichannel and real-time view of conversations on social, all at one place, and create personalized user experiences backed by our intelligence." neg="View conversations on social and help your agents serve your customers." mdxType="Eg" />
    <h3>{`humane`}</h3>
    <p>{`Sprinklr’s core belief is that people never forget how you make them feel. Thus through our voice, we wish to impart a homely feeling of warmth, respect and comfort.`}</p>
    {/*
     Eg.
     Sorry, we could not upload your image. Could you please try again?
     Selected asset could not be uploaded.
     */}
    <Eg pos="Sorry, we could not upload your image. Could you please try again?" neg="Selected asset could not be uploaded. " mdxType="Eg" />
    <h3>{`transparency`}</h3>
    <p>{`We strongly value the trust and faith that our customers put on us with every click, every step and every decision they make. Through our voice, we honor the same by keeping the user informed at every moment.`}</p>
    {/*
     Eg.
     Messages will not be captured from your five deactivated accounts, the rest will be.
     You have five deactivated accounts.
     */}
    <Eg pos="Messages will not be captured from your five deactivated accounts, the rest will be." neg="You have five deactivated accounts." mdxType="Eg" />
    <h3>{`celebratory`}</h3>
    <p>{`Sprinklr takes pride in creating happy customers. Thus, through our voice, we intend to create a delightful experience for our users by celebrating every success, big or small.`}</p>
    {/*
     Eg.
     Congratulations! You have successfully published the Olympics 2020campaign.
     Your Olympics 2020 Campaign has been published.
     */}
    <Eg pos="Congratulations! You have successfully published the Olympics 2020campaign." neg="Your Olympics 2020 Campaign has been published." mdxType="Eg" />
    <h3>{`engaging`}</h3>
    <p>{`Sprinklr cherishes every moment that the users spend on the platform. Thus, through our voice, we are committed to making their experience truly engaging, even during the most mundane moments.`}</p>
    <p>{`For Example: When importing images, we say - "Importing ImageX.jpg" dynamically mentioning the image name instead of simply saying importing images.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      